<template>
  <div>
     <q-form ref="editForm">
      <div class="row">
        <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
          <c-card title="안전순찰 기본정보" class="cardClassDetailForm">
            <template slot="card-button">
              <q-btn-group outline >
                <!-- [S]결재관련 버튼 -->
                <!-- sysApprovalRequestId: 상세조회시 관련 결재요청일련번호 확인 -->
                <!-- popupParam.isApprContent: 결재요청/결재처리 팝업의 component에 해당 페이지 호출시 true -->
                <!-- approvalStatusCd: 결재요청건에 대한 상태코드 -->
                <!-- popupParam.approvalDocType: TODO(결재할문서에서 상세페이지호출시) -->
                <c-appr-btn 
                  ref="appr-btn"
                  name="equipment-daily-appr-btn"
                  :editable="editable"
                  :approvalInfo="approvalInfo"
                  @beforeApprAction="saveDataAppr"
                  @callbackApprAction="approvalCallback"
                  @callbackEndEvent="approvalCallbackEndEvent"
                  @requestAfterAction="getDetail"
                />
                <c-btn
                  v-if="editable && !disabled"
                  :url="saveUrl"
                  :isSubmit="isSave"
                  :param="data"
                  :mappingType="mappingType"
                  label="저장"
                  icon="save"
                  @beforeAction="saveData"
                  @btnCallback="saveCallback" />
                <c-btn v-if="editable && !disabled && isRemove" label="삭제" :editable="editable" icon="delete_forever" @btnClicked="removeData" />
              </q-btn-group>
            </template>
            <template slot="card-detail">
              <div class="col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3">
                <c-plant
                  required
                  :disabled="disabled || updateMode"
                  :editable="editable"
                  :plantCd="data.plantCd"
                  type="edit"
                  name="plantCd"
                  v-model="data.plantCd"
                  @datachange="datachange('A')">
                </c-plant>
              </div>
              <div class="col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3">
                <c-vendor
                  required
                  :disabled="disabled || updateMode"
                  :editable="editable"
                  label="점검업체"
                  name="vendorCd"
                  v-model="data.vendorCd">
                </c-vendor>
              </div>
              <div class="col-xs-12 col-sm-12 col-md-2 col-lg-2 col-xl-2">
                <c-datepicker
                  required
                  :disabled="disabled || updateMode"
                  :editable="editable"
                  label="점검년월"
                  type="month"
                  name="checkMonth"
                  v-model="data.checkMonth"
                  @datachange="datachange('B')">
                </c-datepicker>
              </div>
              <div class="col-xs-12 col-sm-12 col-md-4 col-lg-4 col-xl-4">
                <c-text
                  :required="true"
                  :disabled="disabled"
                  :editable="editable"
                  label="점검명"
                  name="safetyCheckName"
                  v-model="data.safetyCheckName">
                </c-text>
              </div>
            </template>
          </c-card>
        </div>
        <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
          <c-tab
            class="q-mt-sm preStartup-tab-no-padding"
            type="tabcard"
            :tabItems.sync="tabItems"
            :inlineLabel="true"
            :emptyShow="true"
            v-model="tab"
            align="left"
          >
            <template v-slot:default="tab">
              <component
                :is="tab.component"
                :data.sync="data"
                :popupParam.sync="popupParam"
                :grid.sync="grid"
                :contentHeight="contentHeight"
                @getDetail="getDetail"
                @setKey="setKey"
              />
            </template>
          </c-tab>
        </div>
      </div>
      <c-dialog :param="popupOptions">></c-dialog>
    </q-form>
  </div>
</template>

<script>
import { uid } from 'quasar';
import selectConfig from '@/js/selectConfig';
import transactionConfig from '@/js/transactionConfig';
export default {
  name: 'safety-check-detail',
  props: {
    popupParam: {
      type: Object,
      default: () => ({
        safetyCheckId: '',
      }),
    },
    contentHeight: {
      type: [String, Number],
      default: function() {
        return 'auto'
      },
    }
  },
  data() {
    return {
      year: '',
      grid: {
        columns: [],
        data: [],
        height: 'auto',
      },
      data: {
        safetyCheckId: '',  // 안전순찰 일련 번호
        plantCd: '',  // 사업장 코드
        companyCd: '',  // 회사코드
        checkStatusCd: '',  // 계획수립/점검중/점검완료
        checkUserId: '',  // 점검자 ID
        checkMonth: '',  // 점검월
        safetyCheckName: '',  // 점검명
        conforContent: '',  // 부적합사항
        actionContent: '',  // 즉시조치사항
        checkOpinion: '',  // 점검자의견
        regUserId: '',  // 등록자 ID
        chgUserId: '',  // 수정자 ID
        vendorCd: '',

        safetyCheckResultModelList: [], // sheet
        safetyCheckImproveModelList: [], // 개선 list
        safetyCheckUserModelList: [],
        detailList: [],
      },
      popupOptions: {
        isFull: true,
        // width: '80%',
        target: null,
        title: '',
        visible: false,
        top: '',
        param: {},
        closeCallback: null,
      },
      updateMode: false,
      editable: true,
      saveUrl: transactionConfig.sai.safetycheck.plan.insert.url,
      insertUrl: '',
      checkUrl: '',
      updateUrl: '',
      deleteUrl: '',
      completeUrl: '',
      isSave: false,
      isComplete: false,
      isApproval: false,
      mappingType: 'POST',
      getUrl: '',
      resultItemDetail: '',
      currentMonth: '',
      lunarHolidays: [],
      changeItem: {
        plant: '',
        process: '',
        vendor: '',
      },
      today: '',
      tab: 'safetyCheckResult',
      tabItems: [
        { name: 'safetyCheckResult', icon: 'list', label: '안전순찰 결과', component: () => import(`${'./safetyCheckResult.vue'}`) },
        { name: 'safetyCheckImpr', icon: 'health_and_safety', label: '항목별 개선현황', component: () => import(`${'./safetyCheckImpr.vue'}`) },
      ],
    };
  },
  beforeCreate() {},
  created() {},
  beforeMount() {
    Object.assign(this.$data, this.$options.data());
  },
  mounted() {
    this.init();
  },
  beforeDestroy() {
  },
  computed: {
    approvalInfo() {
      return {
        sysApprovalRequestId: this.data.sysApprovalRequestId, // 결재요청번호
        /**
         * 결재상태코드
         * ASC0000001	결재중
         * ASC0000002	반려
         * ASC9999999	결재완료
         */
        approvalStatusCd: this.data.approvalStatusCd, 
        apprEditable: this.data.checkStatusCd && this.data.checkStatusCd !== 'MCSC000015' && this.data.vendorCd === this.$store.getters.user.deptCd, // 결재버튼 활성화 기준
        param: this.data, // 결재 param
        approvalUrl: this.updateUrl, // 결재 url
        isApproval: this.isApproval, // 결재 submit
        approvalTypeCd: 'APTC000004', // 결재유형코드
        isEndFlag: this.data.checkStatusCd && this.data.checkStatusCd !== 'MCSC000015' && this.data.vendorCd === this.$store.getters.user.deptCd,
        endFlagBtnLabel: '월간 안전순찰완료',
        approvalParamValue: { // 결재상세화면 파라미터
          safetyCheckId: this.data.safetyCheckId,
          isApprContent: true
        },
        approvalRequestName: '안전점검 ['+this.data.safetyCheckName+']', // 결재요청명 (문서 title)
        approvalConnId: this.popupParam.safetyCheckId, // 결재연결용 업무일련번호 (문서 key)
      }
    },
    isVendor() {
      if (this.$store.getters.user.innerFlag === 'N') {
        return this.data.vendorCd !== this.$store.getters.user.deptCd;
      } else {
        return false;
      }
    },
    disabled() {
     return this.data.checkStatusCd === 'MCSC000015' || this.data.approvalStatusCd === 'ASC0000001'
          || this.isVendor
    },
    isRemove() {
      return this.popupParam.safetyCheckId && Boolean(!this.data.sysApprovalRequestId)
    },
  },
  watch: {
    contentHeight() {
      let height = (this.contentHeight - 270);
      if (height < 400) {
        height = 500;
      }
      this.grid.height = height + 'px'
    },
  },
  methods: {
    init() {
      // role setting
      this.editable = this.$route.meta.editable;
      this.data.companyCd = this.$store.getters.user.companyCd;
      if (this.$store.getters.user.innerFlag === 'N') {
        this.data.vendorCd = this.$store.getters.user.deptCd;
      }

      this.today= this.$comm.getToday().slice(-2, this.$comm.getToday().length);
      this.currentMonth= this.$comm.getToday().slice(-5, this.$comm.getToday().length -3);
      if (this.today.substring(this.today, 0) === '0') {
        this.today = this.today.substring(this.today, 0)
      }
      // url setting
      this.getUrl = selectConfig.sai.safetycheck.plan.get.url;
      this.checkUrl = selectConfig.sai.safetycheck.plan.check.url;
      this.insertUrl = transactionConfig.sai.safetycheck.plan.insert.url;
      this.updateUrl = transactionConfig.sai.safetycheck.plan.update.url;
      this.deleteUrl = transactionConfig.sai.safetycheck.plan.delete.url;
      this.completeUrl = transactionConfig.sai.safetycheck.plan.complete.url;
      this.resultItemDetail = selectConfig.sai.safetycheck.result.newItem.url;
      this.lunarList = selectConfig.sai.safetycheck.result.lunar.url; // 음력 공휴일구하기
      // code setting
      // list setting
      this.search()
    },
    search() {
      this.getDetail();
    },
    setKey(data) {
      this.popupParam.safetyCheckId = data
      this.getDetail();
    },
    getDetail() {
      if (this.popupParam.safetyCheckId) {
        this.$http.url = this.$format(this.getUrl, this.popupParam.safetyCheckId);
        this.$http.type = 'GET';
        this.$http.request((_result) => {
          this.data = this.$_.clone(_result.data);
          this.updateMode = true;
          this.setColumns();
        },);
      } else {
        this.data.checkMonth = this.$comm.getThisMonth();
        this.datachange('A');
      }
    },
    saveData() {
      if (this.popupParam.safetyCheckId) {
        this.saveUrl = this.updateUrl;
        this.mappingType = 'PUT';
      } else {
        this.saveUrl = this.insertUrl;
        this.mappingType = 'POST';
      }
      this.$refs['editForm'].validate().then(_result => {
        if (_result) {            
          this.$http.url = this.checkUrl;
          this.$http.param = {
            checkMonth: this.data.checkMonth,
            vendorCd: this.data.vendorCd
          }
          this.$http.type = 'GET';
          this.$http.request((_result2) => {
            if (this.mappingType == 'PUT' || _result2.data === 0) {
              window.getApp.$emit('CONFIRM', {
                title: '확인',
                message: '저장하시겠습니까?',
                // TODO : 필요시 추가하세요.
                type: 'info', // success / info / warning / error
                // 확인 callback 함수
                confirmCallback: () => {
                  this.data.regUserId = this.$store.getters.user.userId;
                  this.data.chgUserId = this.$store.getters.user.userId;

                  this.data.safetyCheckResultModelList = this.$_.clone(this.grid.data)

                  if (this.mappingType === 'POST') {
                    this.$_.forEach(this.data.safetyCheckResultModelList, _item => {
                      _item.editFlag = 'C';
                    })
                  }

                  this.isSave = !this.isSave;
                },
                // 취소 callback 함수
                cancelCallback: () => {
                },
              });
            } else {
              window.getApp.$emit('ALERT', {
                title: '안내', // 안내
                message:
                '동일한 점검년월의 안전순찰이 존재합니다.',
                type: 'warning', // success / info / warning / error
              });
              return;
            }
          })
        } else {
          window.getApp.$emit('APP_VALID_ERROR');
        }
      });
    },
    saveCallback(result) {
      window.getApp.$emit('APP_REQUEST_SUCCESS');
      this.popupParam.safetyCheckId = result.data
      this.getDetail();
    },
    datachange(type) {
      if (type === 'A' && this.data.plantCd) {
        this.$http.url = this.$format(this.resultItemDetail, this.data.companyCd, this.data.plantCd);
        this.$http.type = 'GET';
        this.$http.request((_result) => {
          this.data.safetyCheckResultModelList = _result.data;
          this.$_.forEach(this.data.safetyCheckResultModelList, _item => {
            _item.safetyCheckResultId = uid()
          })
          this.setColumns();
        },);
      }
      else if (type === 'B' && this.data.checkMonth) {
        // this.grid.data = [];
        this.setColumns();
      }
    },
    setColumns() {
      let _columns = [
        {
          name: 'checkItemName',
          field: 'checkItemName',
          label: '점검항목',
          align: 'left',
          style: 'width:350px',
          sortable: false,
          fix: true,
        },
      ]
      let year = this.data.checkMonth.substring(0, 4);
      let month = this.data.checkMonth.substring(5);

      // 주말 계산
      let date = new Date(Number(year), Number(month), 0);
      var week = ['일', '월', '화', '수', '목', '금', '토'];

      // 양력 공휴일 계산
      var solorHolidays = [ "01-01", "03-01", "05-05", "06-06", "07-17", "08-15", "10-03", "12-25"]; 

      let days = [];
      if (this.data.checkMonth) {
        this.$http.url = this.$format(this.lunarList, year);
        this.$http.type = 'GET';
        this.$http.request((_result) => {
          // 음력 공휴일 계산은 백엔드에서
          this.lunarHolidays = _result.data

          for (let i=1; i<=date.getDate(); i++) {
            var dayOfWeek = week[new Date(Number(year) + '-' + Number(month) + '-' + i).getDay()];

            var solarIdx = 0;
            let countDay = 0;
            if (i < 10) {
              countDay = '0' + i
              solarIdx = solorHolidays.indexOf(month + '-0' + i);
            } else {
              countDay = i
              solarIdx = solorHolidays.indexOf(month + '-' + i);
            }
            var lunarIdx = 0;
            if (i < 10) {
              lunarIdx = this.lunarHolidays.indexOf(year + month + '0' + i);
            } else {
              lunarIdx = this.lunarHolidays.indexOf(year + month + i);
            }
            
            var day = '';
            if (dayOfWeek === '일' || dayOfWeek === '토' || solarIdx >= 0 || lunarIdx >= 0) {
              day = 'weekend'
            } else {
              day = 'weekday'
            }

            let completeIdx = this.$_.findIndex(this.data.detailList, {dayId: 'day' + i, completeFlag: 'Y'});

            days.push({
              name: 'day' + i + 'Name',
              field: 'day' + i + 'Name',
              key: 'day' + i,
              day: year+'-'+month+'-'+countDay,
              label: i,
              completeIdx: completeIdx,
              currentDay: String(countDay) === String(this.today) && String(month) === String(this.currentMonth) 
                        ? true : false, 
              align: 'center',
              size: 'xs',
              color: completeIdx >= 0 ? 'red' : 'blue-6',
              icon: 'build',
              setHeader: true,
              style: 'width:45px',
              // dayType: day,
              headerColor: day === 'weekend' ? '#FFCDD2' : '',
              type: 'custom',
              headType: 'link',
              sortable: false,
              // component: () => import(`${'./equipmentDeteriorResultProxy.vue'}`)
            })
          }
          this.grid.columns = this.$_.concat(_columns, days);
          this.grid.data = this.$_.clone(this.data.safetyCheckResultModelList)
        },);
      }
          
      if (this.data.checkMonth && !this.popupParam.safetyCheckId) {
        this.data.safetyCheckName = year + '년  ' + month + '월  안전순찰'
      }
    },
    saveDataAppr() {
      window.getApp.$emit('CONFIRM', {
        title: '확인',
        message: '결재요청 하기 전 입력된 값을 저장합니다.\n\r 진행하시겠습니까?',
        // TODO : 필요시 추가하세요.
        type: 'info', // success / info / warning / error
        // 확인 callback 함수
        confirmCallback: () => {
          this.data.regUserId = this.$store.getters.user.userId;
          this.data.chgUserId = this.$store.getters.user.userId;

          this.data.safetyCheckResultModelList = this.$_.clone(this.grid.data)
          let promises = [
            {
              func: this.approvalValue
            },
          ];
          this.$comm.orderedPromise(promises);
        },
        // 취소 callback 함수
        cancelCallback: () => {
        },
      });
    },
    // [S] 결재관련 
    approvalCallback() { // 결재관련 결재요청버튼 callback
      this.$refs['appr-btn'].apprRequestPop();
    },
    approvalValue() {
      return new Promise(resolve => {
        this.isApproval = !this.isApproval
        resolve(true);
      });
    },
    removeData() {
      window.getApp.$emit('CONFIRM', {
        title: '확인',
        message: '삭제하시겠습니까?',
        // TODO : 필요시 추가하세요.
        type: 'warning', // success / info / warning / error
        // 확인 callback 함수
        confirmCallback: () => {
          this.$http.url = this.$format(this.deleteUrl, this.popupParam.safetyCheckId);
          this.$http.type = 'DELETE';
          this.$http.request(() => {
            window.getApp.$emit('APP_REQUEST_SUCCESS');
            this.$emit('closePopup');
          },);
        },
        // 취소 callback 함수
        cancelCallback: () => {
        },
      });
    },
    /* eslint-disable no-unused-vars */
    closePopup() {
      this.popupOptions.target = null;
      this.popupOptions.visible = false;
      this.getDetail();
    },
    approvalCallbackEndEvent() {
      window.getApp.$emit('CONFIRM', {
        title: '확인',
        message: '월간 순찰완료 처리하시겠습니까?',
        // TODO : 필요시 추가하세요.
        type: 'info', // success / info / warning / error
        // 확인 callback 함수
        confirmCallback: () => {
          this.$http.url = transactionConfig.sai.safetycheck.plan.end.url;
          this.$http.type = 'PUT';
          this.$http.param = {
            safetyCheckId: this.popupParam.safetyCheckId,
          }
          this.$http.request(() => {
            this.getDetail();
            window.getApp.$emit('ALERT', {
              title: '안내 ', /* 안내 */
              message: '월간 순찰완료 처리되었습니다.', /* 저장되었습니다. */
              type: 'success', // success / info / warning / error
            });
          },);
        },
        // 취소 callback 함수
        cancelCallback: () => {
        },
      });
    }
  }
};
</script>
